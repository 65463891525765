import Placement from '@/common/Placement';
import Portion from '../../../../common/Portion';
import constants from '../../constants';
import {
  Pizza, PizzaIngredient, PizzaIngredientOption, ToppingsIngredients
} from '../../dataTransformers/builderTypes';
import { isMeat, isVeggie } from '../../identifiers';
import { restrictRulesBySizeAndCrust } from './rulesSelectors';

// #Toppings selectors

const selectPizzaMeatToppings = (pizza: Pizza): PizzaIngredient[] => pizza.meatToppings || [];

const selectPizzaVeggieToppings = (pizza: Pizza): PizzaIngredient[] => pizza.veggieToppings || [];

function numberOfSelectedToppings(pizza: Pizza | ToppingsIngredients) : number {
  const toppingReducer = (sum: number, topping: PizzaIngredient) => {
    let portionVal = topping.portion === Portion.EXTRA ? 2 : 1;

    // TODO: Topping placement should keep track of how many are on left vs right to ensure neither exceed the topping limit
    // decision was made to make that functionality a future ticket since it will require a significant rewrite
    if (topping.placement !== Placement.WHOLE) portionVal *= 0.5;

    return sum + portionVal;
  };
  return [...(pizza.meatToppings || []), ...(pizza.veggieToppings || [])].reduce(toppingReducer, 0);
}

const isReachedMaxAllowedToppings = (
  pizza: Pizza, maxToppings: number
) : boolean => numberOfSelectedToppings(pizza) >= maxToppings;

const isReachedToppingsLimit = (
  pizza: Pizza
): boolean => isReachedMaxAllowedToppings(pizza, constants.MAX_TOPPINGS_ALLOWED);

const isReachedPanCrustToppingsLimit = (
  pizza: Pizza
): boolean => isReachedMaxAllowedToppings(pizza, constants.MAX_TOPPINGS_ALLOWED_FOR_PAN_CRUST);

const isReachedBNYCrustToppingsLimit = (
  pizza: Pizza
): boolean => isReachedMaxAllowedToppings(pizza, constants.MAX_TOPPINGS_ALLOWED_FOR_BNY_CRUST);

const isReachedMaxToppings = (
  pizza: Pizza
) => {
  const maxToppings = pizza.pizzaOptions?.crusts?.find(crust => crust.id === pizza.crust?.id)?.maxAllowed;
  return maxToppings ? isReachedMaxAllowedToppings(pizza, maxToppings) : false;
}

function selectMeatToppingOptions(pizza : Pizza): PizzaIngredientOption[] {
  return pizza.pizzaOptions?.meatToppings?.filter(restrictRulesBySizeAndCrust(pizza, isMeat)) || [];
}

function selectVeggieToppingsOptions(pizza : Pizza): PizzaIngredientOption[] {
  return pizza.pizzaOptions?.veggieToppings?.filter(
    restrictRulesBySizeAndCrust(pizza, isVeggie)
  ) || [];
}

const selectRecipeDefaultToppingsCount = (pizza: Pizza): number => pizza
  ?.recipeDefaultToppingsCount || 0;

export {
  isReachedMaxAllowedToppings,
  isReachedToppingsLimit,
  isReachedPanCrustToppingsLimit,
  isReachedBNYCrustToppingsLimit,
  isReachedMaxToppings,
  numberOfSelectedToppings,
  selectRecipeDefaultToppingsCount,
  selectMeatToppingOptions,
  selectPizzaMeatToppings,
  selectPizzaVeggieToppings,
  selectVeggieToppingsOptions
};
