import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { Cart, CartItem, PhdCart } from '@pizza-hut-us-development/client-core';
import { useRouter } from 'next/router';

import { MELT, PIZZA } from '@/domain/constants';
import ProductId from '@/common/ProductId';
import Routes from '@/router/routes';
import { DealData } from '@/builders/deals/slice/dealTypes';

import { closeCartRail, setEditCartItemId } from '@/clientCore/redux/rail/CartRailSlice';
import { VLU_PREFIX } from '@/clientCore/cart/constants';
import { useCartUpdateItem } from '@/clientCore/cart/hooks/useCartUpdateItem';
import { useCartDeleteItem } from '@/clientCore/cart/hooks/useDeleteItem';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { setCartInfoAndWarningViewType, toggleCartInfoAndWarningView } from '@/clientCore/redux/cart/CartSlice';
import useCCGetDealsMenu from '@/clientCore/temporaryTransformationalHooks/useCCGetDealsMenu';

import parseItemDescription from './helpers/parseItemDescription';
import {
  formatPrice,
  getIsMultiStepDeal,
  getIsSingleStepPizzaDeal,
  getItemUnitPriceByDisplayablePrice,
  getMaxOrderLimitForOccasion
} from './helpers/cartItemHelpers';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { onRailEditLinkClick } from '@/checkout/checkout.analytics';
import { cartItemToLegacy } from '@/clientCore/cart/helpers';
import decodeEntities from '@/graphql/helpers/decodeEntities';
import { VluProductsList } from '../../../../types';
import { EDIT_IN_CART_TYPES } from '@/clientCore/cart/components/CartRail/constants';
import { CartWithLineItems } from './types';

const isIncreaseQuantityButtonDisabled = (subtotal: number, itemPrice: number, orderLimit: number): boolean => subtotal + itemPrice > orderLimit;

// Everything is optional since a UI change in Optimizely could break this otherwise
type HideEditVariables = {
  hiddenItems?: {
    names?: string[];
  };
};

export const useCartItem = ({ item }: { item: CartItem }) => {
  // Needs CC Selectors
  const cart = useSelector(orderSelectors.cart);
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const router = useRouter();

  const [{ enabled: isSingleStepPizzaDealQuantityPickerEnabled, variationKey }] = useDecision('fr-dtg-218-quantity-picker-single-step-pizza-deal');

  const [{ enabled: hideEditEnabled, variables: hideEditVariables}] = useDecision('cb-web-4459-hide_cart_edit');
  const [{ enabled: isMultiStepDealQtyPickerEnabled }] = useDecision('fr-dtg-1060-quantity-picker-multi-step-deals');
  const nonEditableItemNames = hideEditEnabled ? (hideEditVariables as HideEditVariables)?.hiddenItems?.names : [];

  const isSingleStepDealQtyPickerABTestOn = isSingleStepPizzaDealQuantityPickerEnabled && variationKey === 'control_qty_pick_pizza_deal_on';
  const { data: dealData } = useCCGetDealsMenu({});

  // TEMP workaround - Should be removed when Cart type gets updated
  const discountedAmount = (cart as Cart & PhdCart).discountedAmount ?? 0;

  const { occasionId, subtotal } = cart || { occasionId: '', subtotal: 0 };
  const { handleDeleteCartItem } = useCartDeleteItem();
  const { handleUpdateCartItemQuantity } = useCartUpdateItem();
  const storeDetails = useSelector(orderSelectors.store);

  const [vluPrefixDecision] = useDecision('ops-web1554-append_vlu_prefix');

  const vluIdList = vluPrefixDecision.variables?.vlu_list
    ? (vluPrefixDecision.variables as VluProductsList).vlu_list.global_ids
    : undefined;

  const vluYumItemList = vluPrefixDecision.variables?.vlu_list
    ? (vluPrefixDecision.variables as VluProductsList).vlu_list.yum_global_ids
    : undefined;

  const {
    description, name, price, quantity, type, specialInstructions, id, variantCode
  } = item;

  const isDeal = type === 'DISCOUNT';

  const parsedDescription = !isDeal ? parseItemDescription(item, cart as CartWithLineItems) : undefined;

  const handleRemoveItem = () => {
    if (item) {
      analytics.push(() => onRailEditLinkClick('Remove', [cartItemToLegacy(item)]));
    }
    handleDeleteCartItem(item);
  };

  const handleDecreaseItemQuantity = () => handleUpdateCartItemQuantity(item, false) || null;

  const handleIncreaseItemQuantity = () => handleUpdateCartItemQuantity(item) || null;

  const handleEditCartItemClick = () => {
    analytics.push(() => onRailEditLinkClick('Edit', [cartItemToLegacy(item)]));
    dispatch(setEditCartItemId(item.cartItemId));
    if (!EDIT_IN_CART_TYPES.includes(item.type)) {
      router.push(Routes.EDIT_BUILDER);
      dispatch(closeCartRail());
    }
  };

  const handleOpenWarningInfoClick = (text: string) => () => {
    dispatch(setCartInfoAndWarningViewType({ cartInfoOrWarningViewType: text }));
    dispatch(toggleCartInfoAndWarningView({ displayState: true }));
  };

  const maxOrderLimit = getMaxOrderLimitForOccasion({
    occasionList: storeDetails?.occasions || [],
    occasionId
  });
  const itemPrice = getItemUnitPriceByDisplayablePrice({
    itemDisplayablePrice: item.price.toString(),
    itemQuantity: item.quantity || 0
  });

  const subtotalLessDiscountedAmount = subtotal - discountedAmount;

  const increaseQuantityButtonDisabled = isIncreaseQuantityButtonDisabled(
    subtotalLessDiscountedAmount,
    itemPrice,
    maxOrderLimit
  );

  const priceTextContent = formatPrice(price);
  const hasMeltInName = item.name?.toUpperCase().includes(MELT);
  const dealType = item?.modifiers?.[0]?.modifiers?.[0]?.type || '';
  const isSingleStepPizzaDeal = getIsSingleStepPizzaDeal(item);
  const isMultiStepDeal = getIsMultiStepDeal(item);

  const isDealWithMeltPizza = hasMeltInName && isDeal && dealType === PIZZA;
  const shouldShowEditButton = (!hasMeltInName || isDealWithMeltPizza) && !nonEditableItemNames?.includes(item.name);

  const shouldShowQuantityPicker = (
    !isDeal
    || (isSingleStepDealQtyPickerABTestOn && isSingleStepPizzaDeal)
    || (isMultiStepDealQtyPickerEnabled && isMultiStepDeal)
  );

  function getVluPrefix(): string {
    if (!vluPrefixDecision.enabled || !vluIdList || !vluYumItemList) return '';

    const productId = new ProductId(id)?.globalId;
    const isVluItem = !!vluYumItemList?.find(
      (vluItem) => vluItem.id === productId && vluItem.variant_codes.includes(variantCode ?? '')
    );

    if (isVluItem) return VLU_PREFIX;
    return '';
  }

  const legalText = useMemo(
    () => (dealData?.deals?.find((deal) => deal.id === item.id) as unknown as DealData)?.legalText,
    [dealData, item]
  );

  return [
    {
      description: parsedDescription ?? description,
      increaseQuantityButtonDisabled,
      isDeal,
      shouldShowQuantityPicker,
      name: decodeEntities(name),
      priceTextContent,
      quantity,
      shouldShowEditButton,
      specialInstructions,
      legalText
    },
    {
      getVluPrefix,
      handleRemoveItem,
      handleIncreaseItemQuantity,
      handleDecreaseItemQuantity,
      handleEditCartItemClick,
      handleOpenWarningInfoClick
    }
  ];
};
