import { CartItem } from '@pizza-hut-us-development/client-core';
import { DISCOUNT, PIZZA, STEP } from '@/domain/constants';
import {
  GetItemUnitPriceByDisplayablePriceParams,
  GetMaxOrderLimitForOccasionParams
} from '../types';

export const getMaxOrderLimitForOccasion = ({
  occasionList,
  occasionId
}: GetMaxOrderLimitForOccasionParams): number => {
  const maxOrderForOccasion = occasionList?.find(
    (maxOrderLimit) => maxOrderLimit.occasionId === occasionId
  );

  return maxOrderForOccasion?.maxOrder || Infinity;
};

// TODO: This calculation is correct for phdapi but we probably need to check it is the same for Yum
export const getItemUnitPriceByDisplayablePrice = ({
  itemDisplayablePrice,
  itemQuantity
}: GetItemUnitPriceByDisplayablePriceParams): number => parseInt(itemDisplayablePrice, 10) / itemQuantity;

export const formatPrice = (price: number) => `$${(price / 100).toFixed(2)}`;

const getCartItemStepCount = (item: CartItem) => item.modifiers?.filter((modifier) => modifier.type === STEP).length || 0;

export const getIsSingleStepPizzaDeal = (
  item: CartItem
): boolean => {
  const isDeal = item?.type === DISCOUNT;
  const dealType = item?.modifiers?.[0]?.modifiers?.[0]?.type;

  return isDeal && dealType === PIZZA && getCartItemStepCount(item) === 1;
};

export const getIsMultiStepDeal = (
  item: CartItem
): boolean => {
  const isDeal = item?.type === DISCOUNT;

  return isDeal && getCartItemStepCount(item) > 1;
};